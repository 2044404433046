import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import GridIconEdit from './GridIconEdit';
import GridIconDownload from './GridIconDownload';
import { useDownloadInvoice } from '../services/useDownloadInvoice';

interface GridFieldActionsProps {
  baseUrlEdit: string;
}

export default function GridFieldActions(props: GridFieldActionsProps): GridColDef {
  const { isDownloadAvailable } = useDownloadInvoice();

  return {
    field: 'actions',
    headerName: '',
    minWidth: 76,
    maxWidth: 76,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <GridIconEdit urlEdit={props.baseUrlEdit + '/' + params.id} />
          {
            isDownloadAvailable(params.row.detail) &&
            <GridIconDownload id={params.id} />
          }
        </>
      );
    },
  };
}
