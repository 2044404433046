import { Chip } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { StatusColors } from '../constants/StatusColors';

type StatusChipProp = {
  status: string;
};

export default function StatusChip(props: StatusChipProp) {
  const icon =
    props.status == 'complete' ? (
      <CheckOutlinedIcon />
    ) : props.status == 'processing' ? (
      <AutorenewOutlinedIcon />
    ) : (
      <InfoIcon />
    );
  const label = props.status == 'complete' ? 'Complete' : props.status == 'processing' ? 'Processing' : 'No action';
  const color =
    props.status === 'complete' || props.status === 'processing' ? StatusColors[props.status] : StatusColors['other'];

  return (
    <Chip
      icon={icon}
      variant="outlined"
      label={label}
      sx={{ backgroundColor: color, marginTop: '-1px', height: '23px' }}
    />
  );
}
