import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '../../../imports';

import LoadingPage from '../../../components/LoadingPage';
import StatusChip from '../../../components/StatusChip';

import { Booking } from '../../../models/Booking';

import { BookingInit } from '../../../constants/BookingInit';
import { UrlBookings } from '../../../constants/urls';
import { ApiBookings } from '../../../constants/endpoints';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

import { convert_date_full_from_api_full } from '../../../utils/utils';
import IdentifierField from '../../../components/IdentifierField';
import { StatusColors } from '../../../constants/StatusColors';
import { useDownloadInvoice } from '../../../services/useDownloadInvoice';

export default function BookingEdit() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Booking>(BookingInit);

  const { checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();

  const { bookingId } = useParams();
  const axiosHelper = useAxios();
  const { downloadInvoice, isDownloadAvailable } = useDownloadInvoice();

  const updateLoadedFormData = useCallback(
    (data: Booking) => {
      setFormData(data);
      setTitle(data.filekey);
    },
    [setTitle],
  );

  function getDataFromResponse(response: any): Booking {
    return {
      id: (response.id as string) ?? '0',
      type: (response.type as string) ?? '',
      status: (response.status as string) ?? '',
      detail: (response.detail as string) ?? '',
      invoice_service_id: (response.invoice_service_id as string) ?? '',
      invoice_creation_date: (response.invoice_creation_date as string) ?? '',
      pnr_creation_date: (response.pnr_creation_date as string) ?? '',
      departure_date_time: (response.departure_date_time as string) ?? '',
      point_of_sale: (response.point_of_sale as string) ?? '',
      filekey: (response.filekey as string) ?? '',
      last_names: (response.last_names ?? []).join(', '),
      crs: (response.crs as string) ?? '',
      pseudo_city_code: (response.pseudo_city_code as string) ?? '',
      travel_agency_number: (response.travel_agency_number as number) ?? 0,
      name: (response.name as string) ?? '',
      email: (response.email as string) ?? '',
      pnr_status: (response.pnr_status as string) ?? '',
      number_of_adults: (response.number_of_adults as number) ?? 0,
      number_of_children: (response.number_of_children as number) ?? 0,
      number_of_infants: (response.number_of_infants as number) ?? 0,
      currency: (response.currency as string) ?? '',
      total_fare: (response.total_fare as number) ?? 0,
      total_tax: (response.total_tax as number) ?? 0,
      total_surcharges: (response.total_surcharges as number) ?? 0,
      total: (response.total as number) ?? 0,
      vat_applicable_fare_price: (response.vat_applicable_fare_price as number) ?? 0,
      created: (response.created as string) ?? '',
      createdBy: (response.createdBy as string) ?? '',
      updated: (response.updated as string) ?? '',
      updatedBy: (response.updatedBy as string) ?? '',
    };
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const loadFormData = useCallback(() => {
    const uid = Number(bookingId);
    if (uid > 0) {
      setLoading(true);
      axiosHelper
        .get(ApiBookings + '/' + bookingId)
        .then((response) => {
          updateLoadedFormData(
            getDataFromResponse((Object.values(response.data.data.bookings)[0] as Booking) ?? BookingInit),
          );
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add user');
    }
  }, [bookingId, axiosHelper, checkResponseError, setTitle, updateLoadedFormData]);

  useEffect(() => {
    loadFormData();
  }, [bookingId, loadFormData]);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Booking" />
                <CardContent>
                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2.5 }}>
                    <Grid item lg={1} xs={1}>
                      <IdentifierField value={formData.filekey} label="Filekey" fieldName="filekey" />
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container columns={2} spacing={1} sx={{ marginTop: 1 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="pnr_creation_date">Pnr creation date</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="pnr_creation_date"
                          id="pnr_creation_date"
                          label="Pnr creation date"
                          type="text"
                          defaultValue={formData.pnr_creation_date}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="point_of_sale">Point of sale</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="point_of_sale"
                          id="point_of_sale"
                          label="Point of sale"
                          type="text"
                          defaultValue={formData.point_of_sale}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="crs">Reservation system</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="crs"
                          id="crs"
                          label="Reservation system"
                          type="text"
                          defaultValue={formData.crs}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="pseudo_city_code">Pseudo city code</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="pseudo_city_code"
                          id="pseudo_city_code"
                          label="Pseudo city code"
                          type="text"
                          defaultValue={formData.pseudo_city_code}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2.5 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="pnr_status">PNR status</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="pnr_status"
                          id="pnr_status"
                          label="Pnr status"
                          type="text"
                          defaultValue={formData.pnr_status}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container columns={3} spacing={1} sx={{ marginTop: 1 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="number_of_adults">No. of adults</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="number_of_adults"
                          id="number_of_adults"
                          label="No. of adults"
                          type="text"
                          defaultValue={formData.number_of_adults}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="number_of_children">No. of children</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="number_of_children"
                          id="number_of_children"
                          label="No. of children"
                          type="text"
                          defaultValue={formData.number_of_children}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="number_of_infants">No. of infants</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="number_of_infants"
                          id="number_of_infants"
                          label="No. of infants"
                          type="text"
                          defaultValue={formData.number_of_infants}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container columns={1} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="last_names">Lastnames</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="last_names"
                          id="last_names"
                          label="Lastnames"
                          type="text"
                          defaultValue={formData.last_names}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card variant="island">
                <CardHeader title="Invoice" />
                <CardContent>
                  <Grid container columns={2} spacing={1} sx={{ marginBottom: 2.5 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="invoice_service_id">Invoice service</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="invoice_service_id"
                          id="invoice_service_id"
                          label="Invoice service"
                          type="text"
                          defaultValue={formData.invoice_service_id}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="invoice_creation_date">Invoice creation date</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="invoice_creation_date"
                          id="invoice_creation_date"
                          label="Invoice creation date"
                          type="text"
                          defaultValue={formData.invoice_creation_date}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container columns={2} spacing={1} sx={{ marginTop: 1 }}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="total_fare">Total fare</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="total_fare"
                          id="total_fare"
                          label="Total fare"
                          type="text"
                          defaultValue={formData.total_fare + ' ' + formData.currency}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="total_tax">Total tax</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="total_tax"
                          id="total_tax"
                          label="Total tax"
                          type="text"
                          defaultValue={formData.total_tax + ' ' + formData.currency}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="total_surcharges">Total surcharges</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="total_surcharges"
                          id="total_surcharges"
                          label="Total surcharges"
                          type="text"
                          defaultValue={formData.total_surcharges + ' ' + formData.currency}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="total">Total</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="total"
                          id="total"
                          label="Total"
                          type="text"
                          defaultValue={formData.total + ' ' + formData.currency}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container columns={1} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="vat_applicable_fare_price">Vat applicable fare price</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="vat_applicable_fare_price"
                          id="vat_applicable_fare_price"
                          label="Vat applicable fare price"
                          type="text"
                          defaultValue={formData.vat_applicable_fare_price + ' ' + formData.currency}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Travel agency" />
                <CardContent>
                  <Grid container columns={2} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="travel_agency_number">Number</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="travel_agency_number"
                          id="travel_agency_number"
                          label="Number"
                          type="text"
                          defaultValue={formData.travel_agency_number}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container columns={1} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="name"
                          id="name"
                          label="Name"
                          type="text"
                          defaultValue={formData.name}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container columns={1} spacing={1}>
                    <Grid item lg={1} xs={1}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <OutlinedInput
                          fullWidth
                          readOnly
                          name="email"
                          id="email"
                          label="Email"
                          type="text"
                          defaultValue={formData.email}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card
                variant="island"
                sx={{
                  border: 3,
                  borderColor:
                    formData.status === 'complete' || formData.status === 'processing'
                      ? StatusColors[formData.status]
                      : StatusColors['other'],
                }}>
                <CardHeader title="ATOL certificate" action={<StatusChip status={formData.status ?? ''} />} />
                <CardContent>
                  <Box margin="dense">
                    <Typography display="inline" fontSize="13px" color="#B3B4B5" sx={{ fontWeight: 'bold' }}>
                      Created{' '}
                    </Typography>
                    <Typography display="inline" fontSize="13px" color="#B3B4B5">
                      {' • '}
                    </Typography>
                    <Typography display="inline" fontSize="13px" color="#B3B4B5">
                      {formData.created ? convert_date_full_from_api_full(formData.created) : ''}
                    </Typography>
                  </Box>
                  <Box margin="dense">
                    <Typography display="inline" fontSize="13px" color="#B3B4B5" sx={{ fontWeight: 'bold' }}>
                      Last send{' '}
                    </Typography>
                    <Typography display="inline" fontSize="13px" color="#B3B4B5">
                      {' • '}
                    </Typography>
                    <Typography display="inline" fontSize="13px" color="#B3B4B5">
                      {formData.updated ? convert_date_full_from_api_full(formData.updated) : ''}
                    </Typography>
                  </Box>

                  <Stack direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: 2.5 }}>
                    {isDownloadAvailable(formData.detail) && (
                      <Button color="primary" variant="outlined" onClick={() => downloadInvoice(formData.id)}
                              sx={{ ml: 2 }}>
                        Download
                      </Button>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Button
            color="primary"
            variant="contained"
            onClick={() => (window.location.href = UrlBookings)}
            sx={{ ml: 1 }}>
            Close
          </Button>
        </Box>
      )}
    </>
  );
}
