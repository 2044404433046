import useAxios from '../services/useAxios';
import { ApiBookingsDownload } from '../constants/endpoints';
import { toast } from 'react-toastify';
import { ToastPosition } from 'react-toastify/dist/types';

export function useDownloadInvoice() {
  const axiosHelper = useAxios();

  function isDownloadAvailable(detail: string | undefined): boolean {
    return detail?.startsWith('certificate') ?? false;
  }

  const downloadPdf = (url: string, filename: string): void => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const getFileName = (contentDisposition: string): string => {
    let filename = 'ATOL-certificate.pdf';
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch) {
        filename = filenameMatch[1];
      }
    }
    return filename;
  };

  const downloadInvoice = (id: string | number): void => {
    axiosHelper.post(ApiBookingsDownload, { id: id }, {}, 'arraybuffer')
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        let filename = getFileName(response.headers['content-disposition']);
        downloadPdf(url, filename);
      })
      .catch(async (error) => {
        try {
          let decodedString = new TextDecoder('utf-8').decode(error.response.data);
          const data = JSON.parse(decodedString);
          if (data.message) {
            toast.error(`Download failed: ${data.message}`, {
              position: 'top-center' as ToastPosition,
            });
          }
        } catch (e) {
          toast.error(`Download failed`, {
            position: 'top-center' as ToastPosition,
          });
        }
      });
  };

  return {
    downloadInvoice,
    isDownloadAvailable,
  };
}
