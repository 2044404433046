import { GridColDef } from '@mui/x-data-grid';

import { ApiBookings } from '../../../constants/endpoints';
import {UrlBookings} from '../../../constants/urls';

import DataGridRestSort from '../../../components/DataGridRestSort';
import { Stack } from '@mui/material';

import StatusChip from "../../../components/StatusChip";
import GridFieldActions from "../../../components/GridFieldActions";

export default function BookingsList() {

  const columns: GridColDef[] = [
    { field: 'filekey', headerName: 'Filekey' },
    { field: 'last_names', headerName: 'Names' },
    { field: 'type', headerName: 'Type' },
    { field: 'travel_agency_number', headerName: 'TA number' },
    {
      field: 'total',
      headerName: 'Total',
      renderCell: (params) => {
        return params.row.total + ' ' + params.row.currency;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              <StatusChip status={params.row.status} />
            </Stack>
          </>
        );
      },
    },
    GridFieldActions({ baseUrlEdit: UrlBookings }),
  ];

  return (
    <>
      <DataGridRestSort
        columns={columns}
        endpoint={ApiBookings}
        navigation={UrlBookings}
        rootNode="bookings"
        sortModel={{ field: 'filekey', sort: 'asc' }}
        hideButtonNew={true}
      />
    </>
  );
}
