import { RouterProvider } from 'react-router-dom';

import Router from './components/Router';
import RouterNotAuthorized from './components/RouterNotAuthorized';
import { useAuth } from './services/useAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const { user } = useAuth();
  const router = user.token ? Router() : RouterNotAuthorized();

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}
