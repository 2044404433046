import { Autocomplete, Paper, SearchIcon, TextField } from '../imports';

import ListData from '../models/ListData';

import { debounce } from '@mui/material/utils';

interface ListFilterProps {
  placeholder: string;
  onChange: (value: string) => void;
}

export function filterData<T extends ListData>(data: T[], filter: string) {
  return data.filter((item) => {
    return filter === '' || item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
  });
}

export default function ListFilter(props: ListFilterProps) {
  const changeInputValue = debounce((value: string) => {
    if (value.length >= 3) {
      props.onChange(value);
    } else {
      props.onChange('');
    }
  }, 500);

  const handleInputChange = (_event: any, value: string): void => {
    changeInputValue(value);
  };

  return (
    <Paper variant="outlined" sx={{ my: 2, pl: 1, pr: 1, display: 'flex', alignItems: 'center', minWidth: '400px' }}>
      <Autocomplete
        autoComplete
        freeSolo={true}
        onInputChange={handleInputChange}
        options={[]}
        sx={{ mt: 0.3, flex: 1 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            fullWidth
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
      <SearchIcon sx={{ mt: 0.3, color: '#0000001f' }} />
    </Paper>
  );
}
