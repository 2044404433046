import {
  FeedIcon,
  PeopleIcon,
  SpeedIcon,
} from '../../imports';

import Dashboard from '../../pages/Dashboard';
import NotFound from '../../pages/NotFound';
import Profile from '../../pages/Profile';
import BookingsEdit from '../../pages/Bookings/BookingsEdit';
import BookingsList from '../../pages/Bookings/BookingsList';
import UsersEdit from '../../pages/Users/UsersEdit';
import UsersList from '../../pages/Users/UsersList';

import PageConfig from '../../models/PageConfig/PageConfig';

import {
  UrlDashboard,
  UrlUsers,
  UrlProfile, UrlBookings,
} from '../urls';

import { UserRoleUser, UserRoleAdmin } from '../utils';

let config: PageConfig = {
  error: {
    name: 'Not Found',
    path: '*',
    element: <NotFound />,
    role: UserRoleUser,
  },
  dashboard: {
    name: 'Dashboard',
    path: UrlDashboard,
    icon: <SpeedIcon />,
    element: <Dashboard />,
    role: UserRoleUser,
  },
  bookings: {
    name: 'Bookings',
    path: UrlBookings,
    icon: <FeedIcon />,
    element: <BookingsList />,
    role: UserRoleUser,
    children: {
      edit: {
        name: 'Booking edit',
        path: ':bookingId',
        element: <BookingsEdit/>,
        role: UserRoleUser,
      },
    },
  },
  users: {
    name: 'Users',
    path: UrlUsers,
    icon: <PeopleIcon />,
    element: <UsersList />,
    role: UserRoleAdmin,
    children: {
      edit: {
        name: 'User edit',
        path: ':userId',
        element: <UsersEdit />,
        role: UserRoleAdmin,
      },
    },
  },
  profile: {
    name: 'Profile',
    path: UrlProfile,
    icon: <PeopleIcon />,
    element: <Profile />,
    role: UserRoleUser,
  },
};

Object.keys(config).forEach(
  (key) => (config[key].path = config[key].path.startsWith('/') ? config[key].path.substring(1) : config[key].path)
);

export const pageConfig = config;
