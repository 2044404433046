import { format } from 'date-fns';
import { DateFormatApiFull } from './utils';

import { Booking } from '../models/Booking';

export const BookingInit: Booking = {
  id: '0',
  type: '',
  status: '',
  detail: '',
  invoice_service_id: '',
  invoice_creation_date: '',
  pnr_creation_date: '',
  departure_date_time: '',
  point_of_sale: '',
  filekey: '',
  last_names: '',
  crs: '',
  pseudo_city_code: '',
  travel_agency_number: 0,
  name: '',
  email: '',
  pnr_status: '',
  number_of_adults: 0,
  number_of_children: 0,
  number_of_infants: 0,
  currency: '',
  total_fare: 0,
  total_tax: 0,
  total_surcharges: 0,
  total: 0,
  vat_applicable_fare_price: 0,
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
};
