import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
} from '../../../imports';

import HistoryCard from '../../../components/HistoryCard';
import LoadingPage from '../../../components/LoadingPage';
import EditButtons from '../../../components/EditButtons';

import { UserEdit } from '../../../models/user/UserEdit';

import { UserEditInit } from '../../../constants/UserEditInit';
import { UrlUsers } from '../../../constants/urls';
import { ApiUsers } from '../../../constants/endpoints';
import { DataSendStatusInit } from '../../../constants/DataSendStatus/DataSendStatusInit';
import { Salutations } from '../../../constants/Salutations';
import { UserRoleAdmin, UserRoleOperator, UserRoleUser } from '../../../constants/utils';

import useAxios from '../../../services/useAxios';
import { useDataSendStatus } from '../../../services/useDataSendStatus';
import usePageConfig from '../../../services/usePageConfig';

import { ucfirst } from '../../../utils/utils';

export default function UsersEdit() {
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [action, setAction] = useState('add');
  const [formData, setFormData] = useState<UserEdit>(UserEditInit);
  const [loadedFormData, setLoadedFormData] = useState<UserEdit>(UserEditInit);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { setDataSendStatus, checkResponseError } = useDataSendStatus();
  const { setTitle } = usePageConfig();
  const navigate = useNavigate();
  const { userId } = useParams();
  const axiosHelper = useAxios();

  const onRoleChange = (event: SelectChangeEvent) => {
    const fd = Object.assign({}, formData);
    fd.role = event.target.value as string;
    setFormData(fd);
  };

  const onSalutationChange = (event: SelectChangeEvent) => {
    const fd = Object.assign({}, formData);
    fd.salutation = event.target.value as string;
    setFormData(fd);
  };

  const onStatusChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.checked;
    const fd = Object.assign({}, formData);
    fd.status = newValue ? 'active' : 'inactive';
    setFormData(fd);
  };

  const updateLoadedFormData = useCallback(
    (data: UserEdit) => {
      setLoadedFormData(data);
      setFormData(data);
      setTitle(data.name);
    },
    [setTitle]
  );

  function getDataFromResponse(response: any): UserEdit {
    return {
      id: (response.id as string) ?? '0',
      salutation: (response.salutation as string) ?? '',
      firstName: response.firstName as string,
      lastName: response.lastName as string,
      name: (response.name as string) ?? '',
      email: response.email as string,
      phone: (response.phone as string) ?? '',
      status: (response.status as string) ?? '',
      role: (response.role as string) ?? '',
      created: (response.created as string) ?? '',
      updated: (response.updated as string) ?? '',
      createdBy: (response.createdBy as string) ?? '',
      updatedBy: (response.updatedBy as string) ?? '',
    };
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDataSendStatus(DataSendStatusInit);
    setSendingData(true);

    let request: Promise<any>;
    if (action === 'edit') {
      request = axiosHelper.put(ApiUsers + '/' + userId, formData);
    } else {
      request = axiosHelper.post(ApiUsers, formData);
    }

    request
      .then((response) => {
        if (action === 'edit') {
          setDataSendStatus({ open: true, success: true, message: 'Data updated' });
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.users)[0]));
        } else {
          setDataSendStatus({ open: true, success: true, message: 'User created' });
          navigate(UrlUsers);
        }
      })
      .catch(checkResponseError)
      .finally(() => setSendingData(false));
  };

  const loadFormData = useCallback(() => {
    if (userId !== '0') {
      setLoading(true);
      axiosHelper
        .get(ApiUsers + '/' + userId)
        .then((response) => {
          updateLoadedFormData(getDataFromResponse(Object.values(response.data.data.users)[0] ?? UserEditInit));
        })
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    } else {
      setTitle('Add user');
    }
  }, [userId, axiosHelper, checkResponseError, setTitle, updateLoadedFormData]);

  useEffect(() => {
    if (userId !== '0') {
      setAction('edit');
    } else {
      setAction('add');
    }

    loadFormData();
  }, [userId, loadFormData]);

  function deleteDialogCallback(result: boolean) {
    setDeleteDialogOpen(false);

    if (result) {
      setLoading(true);

      axiosHelper
        .delete(ApiUsers + '/' + loadedFormData.id)
        .then(() => navigate(UrlUsers))
        .catch(checkResponseError)
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', maxWidth: 1024 }}>
          <FormControl sx={{ float: 'right', mb: 3 }}>
            <FormControlLabel
              control={<Switch name="status" checked={formData.status === 'active'} onChange={onStatusChange} />}
              label={ucfirst(formData.status ?? '')}
            />
          </FormControl>
          <Grid container columns={2} rowSpacing={0} columnSpacing={'15px'}>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Details" />
                <CardContent>
                  <FormControl margin="dense" sx={{ width: '130px' }}>
                    <InputLabel id="salutation">Salutation</InputLabel>
                    <Select
                      labelId="salutation"
                      id="salutation"
                      value={formData.salutation}
                      label="Salutation"
                      name="salutation"
                      onChange={onSalutationChange}>
                      {Salutations.map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.salutation}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="firstName">First name</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="firstName"
                      id="firstName"
                      label="First name"
                      type="text"
                      value={formData.firstName}
                      onChange={onChange}
                    />
                  </FormControl>

                  <FormControl margin="dense" fullWidth>
                    <InputLabel htmlFor="lastName">Last name</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="lastName"
                      id="lastName"
                      label="Last name"
                      type="text"
                      value={formData.lastName}
                      onChange={onChange}
                    />
                  </FormControl>

                  <FormControl fullWidth margin="dense">
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="email"
                      id="email"
                      label="Email"
                      type="text"
                      value={formData.email}
                      onChange={onChange}
                    />
                  </FormControl>

                  <FormControl fullWidth margin="dense">
                    <InputLabel htmlFor="phone">Phone</InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="phone"
                      id="phone"
                      label="Phone"
                      type="text"
                      value={formData.phone}
                      onChange={onChange}
                    />
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={1} xs={2}>
              <Card variant="island">
                <CardHeader title="Permissions" />
                <CardContent>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                      labelId="role"
                      id="role"
                      value={formData.role}
                      label="Role"
                      name="role"
                      onChange={onRoleChange}>
                      {[UserRoleAdmin, UserRoleOperator, UserRoleUser].map((v) => {
                        return (
                          <MenuItem value={v} key={v} selected={v === formData.role}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
              {action === 'edit' && (
                <HistoryCard
                  createdBy={formData.createdBy}
                  created={formData.created}
                  updatedBy={formData.updatedBy}
                  updated={formData.updated}
                />
              )}
            </Grid>
          </Grid>

          <EditButtons
            action={action}
            itemName={loadedFormData.name}
            entityName="user"
            sendingData={sendingData}
            deleteDialogOpen={deleteDialogOpen}
            deleteDialogCallback={deleteDialogCallback}
            onReset={() => updateLoadedFormData(loadedFormData)}
            onDelete={() => setDeleteDialogOpen(true)}
          />
        </Box>
      )}
    </>
  );
}
