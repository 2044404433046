import { format } from 'date-fns';
import { DateFormatApiFull } from './utils';

import { UserEdit } from '../models/user/UserEdit';

export const UserEditInit: UserEdit = {
  id: '0',
  salutation: '',
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  phone: '',
  status: 'inactive',
  role: 'user',
  created: format(new Date(), DateFormatApiFull),
  updated: format(new Date(), DateFormatApiFull),
  createdBy: '',
  updatedBy: '',
};
