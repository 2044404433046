import { FormControl, InputLabel, OutlinedInput } from '../imports';

interface IdentifierFieldProps {
  value: string;
  label?: string;
  fieldName?: string;
}

export default function IdentifierField(props: IdentifierFieldProps) {
  const label = props.label ?? 'Identifier';
  const fieldName = props.fieldName ?? 'identifier';

  return (
    <FormControl margin="dense" fullWidth>
      <InputLabel htmlFor={fieldName}>{label}</InputLabel>
      <OutlinedInput
        fullWidth
        readOnly
        name={fieldName}
        sx={{ backgroundColor: 'rgba(133, 188, 67, 0.3)' }}
        id={fieldName}
        label={label}
        type="text"
        defaultValue={props.value}
      />
    </FormControl>
  );
}
