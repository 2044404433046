import { AxiosResponse } from 'axios';
import { differenceInYears, format, isToday, isYesterday, parseJSON } from 'date-fns';

import { DateFormatFull, DateFormatInput, UserRoleAdmin, UserRoleOperator, UserRoleUser } from '../constants/utils';

export function save_file(response: AxiosResponse) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const headerLine = response.headers['content-disposition'];
  const filename = headerLine.substring(headerLine.indexOf('"') + 1, headerLine.lastIndexOf('"'));
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function ucfirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function check_user_access(requiredRole: string, userRole: string): boolean {
  return (
    userRole === UserRoleAdmin ||
    (requiredRole === UserRoleOperator && userRole === UserRoleOperator) ||
    requiredRole === UserRoleUser
  );
}

export function convert_date_full_from_api_full(date: string) {
  return format(parseJSON(date), DateFormatFull);
}

export function convert_date_from_api_full(date: string) {
  return format(parseJSON(date), DateFormatInput);
}

export function updated_as_string(date: string): string {
  if (date) {
    const d = parseJSON(date);

    if (isToday(d)) {
      return 'today';
    } else if (isYesterday(d)) {
      return 'yesterday';
    } else if (differenceInYears(new Date(), d) >= 1) {
      return '>1y ago';
    } else {
      return format(d, DateFormatInput);
    }
  }

  return '';
}
