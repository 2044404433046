import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardContent, Grid, Switch } from '../imports';
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { GridSortItem } from '@mui/x-data-grid/models/gridSortModel';

import ListFilter from './ListFilter';
import Filters from './Filters';

import ListData from '../models/ListData';
import FilterData from '../models/Filter/FilterData';

import { PageSize, PageSizeOptions } from '../constants/utils';

import useAxios from '../services/useAxios';
import { useDataSendStatus } from '../services/useDataSendStatus';

interface DataGridRestSortProps {
  columns: GridColDef[];
  endpoint: string;
  navigation: string;
  paginationModel?: GridPaginationModel;
  rootNode: string;
  sortModel?: GridSortItem;
  filters?: string;
  statusSwitcherColumn?: boolean;
  hideButtonNew?: boolean;
  searchPlaceholder?: string;
}

interface DataGridRestState {
  loading: boolean;
  rows: ListData[];
  pageInfo: {
    totalRowCount?: number;
    pageSize?: number;
    currentPage?: number;
  };
}

export const iconStyle = {
  fontSize: '18px',
};

export default function DataGridRestSort(props: DataGridRestSortProps) {
  const axiosHelper = useAxios();
  const { checkResponseError } = useDataSendStatus();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [filtersData, setFiltersData] = useState<FilterData[]>([]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    props?.paginationModel || {
      page: 0,
      pageSize: PageSize,
    }
  );
  const [state, setState] = useState<DataGridRestState>({
    loading: false,
    rows: [],
    pageInfo: {},
  });
  const [rowCountState, setRowCountState] = useState(state.pageInfo?.totalRowCount || 0);
  const [sortModel, setSortModel] = useState<GridSortItem>(props.sortModel ?? { field: 'updated', sort: 'desc' });

  let columns = [...props.columns];
  if (props.statusSwitcherColumn) {
    columns = [
      {
        field: 'status',
        headerName: '',
        renderCell: (params) => {
          return (
            <Switch
              name="status"
              checked={params.value === 'active'}
              onChange={(_, checked) => {
                setState((prevState) => ({ ...prevState, loading: true }));

                axiosHelper
                  .post(props.endpoint + '/status/' + params.id, {
                    status: checked ? 'active' : 'inactive',
                  })
                  .then(() => loadData())
                  .catch(checkResponseError)
                  .finally(() => setState((prevState) => ({ ...prevState, loading: false })));
              }}
            />
          );
        },
        flex: 0.2,
      },
      ...props.columns,
    ];
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel({ ...model[0] });
    loadData();
  };

  function handleFiltersDataChange(filtersData: FilterData[]) {
    setFiltersData(filtersData);
    setPaginationModel({ ...paginationModel, page: 0 });
  }

  columns.forEach((column) => column.flex || (column.flex = 1));

  const params = useMemo(() => {
    const params = new URLSearchParams();

    if (search) {
      params.set('search', search);
    }

    if (paginationModel.page > 0) {
      params.set('offset', String(paginationModel.page * paginationModel.pageSize));
    }

    params.set('limit', String(paginationModel.pageSize));

    if (sortModel.field !== undefined) {
      params.set('order[field]', sortModel.field);
      params.set('order[sort]', sortModel.sort ?? 'asc');
    }

    if (filtersData.length) {
      params.set('filters', JSON.stringify(filtersData));
    }

    return params;
  }, [search, filtersData, paginationModel, sortModel.field, sortModel.sort]);

  const loadData = useCallback(() => {
    setState((prevState) => ({ ...prevState, loading: true }));

    axiosHelper
      .get(props.endpoint + '?' + params.toString())
      .then((response) => {
        if (response.data.meta && response.data.meta.success) {
          const rows = Object.values(response.data.data[props.rootNode] ?? []) as ListData[];

          if (sortModel.field && sortModel.sort) {
            const field = sortModel.field as keyof ListData;

            if (sortModel.sort === 'asc') {
              rows.sort((a, b) => {
                const aValue = String(a[field] ?? '');
                const bValue = String(b[field] ?? '');
                if (aValue.toLowerCase() < bValue.toLowerCase()) {
                  return -1;
                }
                if (aValue.toLowerCase() > bValue.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
            } else {
              rows.sort((a, b) => {
                const aValue = String(a[field] ?? '');
                const bValue = String(b[field] ?? '');
                if (aValue.toLowerCase() > bValue.toLowerCase()) {
                  return -1;
                }
                if (aValue.toLowerCase() < bValue.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
            }
          }

          setState({
            loading: false,
            rows: rows,
            pageInfo: {
              totalRowCount: response.data.meta.totalRowCount,
              pageSize: response.data.meta.pageSize,
            },
          });
        }
      })
      .catch(checkResponseError);
  }, [axiosHelper, checkResponseError, params, props.endpoint, props.rootNode, sortModel.field, sortModel.sort]);

  useEffect(loadData, [loadData]);

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      state.pageInfo?.totalRowCount !== undefined ? state.pageInfo?.totalRowCount : prevRowCountState
    );

    if (props.sortModel) {
      setSortModel(props.sortModel);
    }
  }, [state.pageInfo?.totalRowCount, setRowCountState, props.sortModel]);

  return (
    <>
      <Card variant="list">
        <CardContent>
          <Grid container columns={5} spacing={0}>
            <Grid item xs={4} sx={{ my: -0.8 }}>
              <ListFilter
                onChange={setSearch}
                placeholder={props.searchPlaceholder ?? 'Search by filekey or names ...'}
              />
            </Grid>

            {!props.hideButtonNew && (
              <Grid item xs={1} textAlign="right">
                <Button
                  size="medium"
                  variant="contained"
                  sx={{ my: 1, mr: 1 }}
                  onClick={() => navigate(props.navigation + '/0')}>
                  New
                </Button>
              </Grid>
            )}
            {props.hideButtonNew && <Grid item xs={1} sx={{ my: -0.8 }} />}
          </Grid>

          {props.filters && <Filters filters={props.filters} setFiltersData={handleFiltersDataChange} />}
        </CardContent>
      </Card>

      <Card variant="list">
        <CardContent>
          <DataGrid
            autoHeight
            rowHeight={40}
            columns={columns}
            disableColumnMenu={true}
            disableRowSelectionOnClick={true}
            loading={state.loading}
            rowCount={rowCountState}
            rows={state.rows}
            pageSizeOptions={PageSizeOptions}
            paginationMode="server"
            paginationModel={paginationModel}
            initialState={{
              sorting: {
                sortModel: [sortModel],
              },
            }}
            sx={{
              border: 0,
              lineHeight: '40px',
              '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
              '& .disabled': { color: '#C1C1C1' },
            }}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
        </CardContent>
      </Card>
    </>
  );
}
