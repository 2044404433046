import { Box, FileDownloadIcon, IconButton } from '../imports';
import { iconStyle } from './DataGridRestSort';
import { GridRowId } from '@mui/x-data-grid/models/gridRows';
import { useDownloadInvoice } from '../services/useDownloadInvoice';

interface GridIconDownloadProps {
  id: GridRowId;
}

export default function GridIconDownload(props: GridIconDownloadProps) {
  const { downloadInvoice } = useDownloadInvoice();
  return (
    <Box component="span" width={28}>
      <IconButton size="small" onClick={() => downloadInvoice(props.id)}>
        <FileDownloadIcon sx={iconStyle} />
      </IconButton>
    </Box>
  );
}
