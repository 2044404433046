import { Card, CardContent, Container, Grid } from '../../imports';

export default function Dashboard() {
  return (
    <Container maxWidth="lg">
      <Grid container columns={3}>
        <Grid item lg={1} xs={1}>
          <Card variant="island" sx={{ minHeight: 270 }}>
            <CardContent></CardContent>
          </Card>
        </Grid>
        <Grid item lg={1} xs={1}>
          <Card variant="island" sx={{ minHeight: 270 }}>
            <CardContent></CardContent>
          </Card>
        </Grid>
        <Grid item lg={1} xs={1}>
          <Card variant="island" sx={{ minHeight: 270 }}>
            <CardContent></CardContent>
          </Card>
        </Grid>
        <Grid item lg={3}>
          <Card variant="island" sx={{ minHeight: 440, mt: 0 }}>
            <CardContent></CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
